<template>

  <b-overlay
    :show="loading"
    rounded="sm"
  >

    <b-card title="Add new template">

      <validation-observer ref="simpleRules">

        <b-form @submit.prevent>
          <b-row>

            <b-col cols="8">
              <b-form-group
                label="Template Name"
                label-for="v-templateName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="v-templateName"
                    v-model="template.label"
                    placeholder="Template Name"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Headers At"
                label-for="v-headerAt"
              >
                <validation-provider
                  #default="{ errors }"
                  name="header at"
                  rules="required|integer"
                >
                  <b-form-input
                    id="v-headerAt"
                    v-model="template.headerAt"
                    placeholder="Headers At"
                    type="number"
                    required
                    :state="errors.length > 0 ? false:null"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="default Bq DataSet"
                label-for="v-defaultBqDataSet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="defaultBqDataSet"
                  rules="required"
                >
                  <b-form-input
                    id="v-defaultBqDataSet"
                    v-model="template.defaultBqDataSet"
                    placeholder="default Bq DataSet"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="default Bq Table"
                label-for="v-defaultBqTable"
              >
                <validation-provider
                  #default="{ errors }"
                  name="defaultBqTable"
                  rules="required"
                >
                  <b-form-input
                    id="v-defaultBqTable"
                    v-model="template.defaultBqTable"
                    placeholder="default BQ Table"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Add Created At "
                label-for="v-addCreatedAt"
              >
                <b-form-checkbox
                  v-model="template.addCreatedAt"
                  value="true"
                > True
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="loading"
                @click="saveTemplate"
              >
                Add
              </b-button>

              <router-link :to="{name:'documents-templates'}">

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >

                  Back
                </b-button>

              </router-link>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  required, email, numeric, integer,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      template: {},
    }
  },
  methods: {
    saveTemplate() {
      this.$refs.simpleRules.validate().then(valid => {
        console.log('Valid : ', valid)

        if (valid) {
          this.loading = true

          this.$store.dispatch('documentTemplate/create', this.template)
            .then(() => {
              this.$router.push({ name: 'documents-templates' })
            },
            error => {
              this.showToast('Error', error.message || 'Error while saving', 'danger')
            }).finally(() => {
              this.loading = false
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
